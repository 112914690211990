import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
	// mode: 'history',
	routes: [
		{
			path: '/',
			component: () => import('@/views/index.vue'),
			redirect: '/home',
			children: [
				{
					path: '/home',
					component: () => import('@/views/home.vue')
				},
				{
					path: '/country',
					component: () => import('@/views/country.vue')
				}
			]
		}
	],
	scrollBehavior() {
		return {
			x: 0,
			y: 0
		}
	}
})

import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import '@/styles/reset.scss'
import '@/styles/animationH5.css'
import 'animate.css'
import BaiduMap from 'vue-baidu-map'
// 导入 NProgress 包对应的JS和CSS
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import axios from 'axios'
import './utils/vant.js'
import VueScroller from 'vue-scroller'
import vuescroll from 'vuescroll' //引入vuescroll
import 'vuescroll/dist/vuescroll.css' //引入vuescroll样式
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(vuescroll) //使用
Vue.use(VueScroller)
Vue.use(ElementUI)
Vue.use(BaiduMap, {
	ak: 'nGncjw6WCD12OodeKQolUDdDhExhfzw1'
})
// 配置请求的跟路径
axios.defaults.baseURL = process.env.VUE_APP_BASE_API
// 在 request 拦截器中，展示进度条 NProgress.start()
axios.interceptors.request.use((config) => {
	if (config.url != 'hwapp/juquHwWwwPage/un/click') {
		NProgress.start()
	}
	config.headers.Authorization = window.sessionStorage.getItem('token')
	// 在最后必须 return config
	return config
})
// 在 response 拦截器中，隐藏进度条 NProgress.done()
axios.interceptors.response.use((config) => {
	NProgress.done()
	return config
})
Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
	router,
	render: (h) => h(App)
}).$mount('#app')
